.home-page-container {
  margin-top: 50px;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;

  h1 {
    align-self: center;
  }

  .news-items-container {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .home-page-container {
    padding: 20px 70px;
  }
}

@media (min-width: 1200px) {
  .home-page-container {
    padding: 20px 150px;
  }
}
