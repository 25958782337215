.main-nav-container {
  display: flex;
  background-color: #000c19;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;

  .nav-title-container {
    margin-top: 12px;
    margin-left: 20px;

    .title-text,
    a {
      color: cyan;
      font-family: Arial, Helvetica, sans-serif;
      font-size: x-large;
      font-weight: bold;
      text-decoration: none;
    }

    .title-text {
      padding-bottom: 10px;

      &:hover {
        color: lightcyan;
      }
    }
  }

  .links-container {
    height: 100%;
    position: fixed;
    left: 0;
    top: 49px;
    background: #000c19;
    opacity: 0.7;
    transition: transform 0.2s ease-in-out 0s;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &.none {
      display: none;
    }

    .list {
      list-style-type: none;
      padding: 20px 15px;

      .link-item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: none;
        }

        a {
          color: cyan;
          font-family: Arial, Helvetica, sans-serif;
          font-size: medium;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            color: lightcyan;
          }
        }
      }
    }
  }
}

@media (min-width: 767px) {
  .main-nav-container {
    .links-container {
      height: unset;
      position: unset;
      background: unset;
      opacity: unset;
      transition: unset;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;

      &.none {
        display: unset;
      }

      .list {
        display: flex;
        padding: 16px 40px;

        .link-item {
          margin: 0;

          a {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .main-nav-container {
    .title-text {
      cursor: pointer;
    }

    .links-container {
      .list {
        .link-item {
          cursor: pointer;

          a {
            margin-right: 40px;
          }
        }
      }
    }
  }
}
